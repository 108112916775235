import React from "react"
import ParagraphItalic from "../atoms/paragraph-italic"
import Heding from "../atoms/newsletter-heading"
import Wrapper from "../atoms/page-wrapper"

const AboutPage = () => (
  <Wrapper>
    <ParagraphItalic>o-nas</ParagraphItalic>
    <Heding>Dowiedz się o nas więcej</Heding>
    <br />
    <br />
    <p>
      CENTRUM EDUKACJI AMUZO to sprawdzone miejsce dla Twojego dziecka. Nauka
      jezyków obcych dla dzieci w każdym wieku. Dbamy o równomierny postęp
      znajomość języka angielskiego Waszych dzieci. W pracy z uczniami
      korzystamy z nowoczesnych multimedialnych technik metodycznych. Uczniowie
      rozwijają wszystkie kompetencje językowe: mówienie, pisanie, czytanie i
      słuchanie. Zapewniamy Twojemu dziecku odpowiedni dobór grupy nie tylko pod
      względem wieku, ale także poziomu znajomości języka angielskiego Układamy
      plan zajęć, uwzględniając państwa sugestie i propozycje. Zapraszamy
      uczniów: - w wieku przedszkolnym - Szkoły Podstawowej - Szkół Średnich
      Organizujemy kursy przygotowawcze do egzaminu 8-klasisty  i  maturalnego .
      <br />
      <br />
    </p>
    <h4>Warto wybrać AMUZO, ponieważ:</h4>
    <ul>
      <li>
        Jesteśmy szkołą z wieloletnim doświadczeniem, a opinie naszych
        absolwentów są najlepszą wizytówką naszej szkoły
      </li>
      <li>
        Oferujemy pełen zakres kursów językowych dla wszystkich grup wiekowych
        (dzieci, młodzieży, dorosłych), kursy przygotowujące do egzaminów
        gimnazjalnych, matury.
      </li>
      <li>
        Uczymy skutecznie i nowocześnie. Do nauczania używamy środków
        audiowizualnych, inspirujących pomocy dydaktycznych i nowatorskich
        podręczników.
      </li>
      <li>
        {" "}
        Współpraca z rodzicami jest jednym z naszych priorytetów. Mają oni
        możliwość otrzymania bieżących informacji o postępach swoich dzieci.
      </li>
      <li>
        {" "}
        Prowadzimy zajęcia w oparciu o odpowiednio przygotowane programy
        nauczania.
      </li>
      <li>Zapewniamy ciągłość nauki: od 3 lat aż do końca szkoły średniej.</li>
      <li> Promujemy kulturę krajów angielskiego obszaru językowego.</li>
      <li>Układamy plan zajęć, uwzględniając państwa sugestie i propozycje.</li>
      <li>
        W przypadku dłuższej nieobecności dziecka na zajęciach proponujemy
        bezpłatny Help Club – możliwośc nadrobienia zaległości w grupie
        równoległej.
      </li>
      <li>Tworzymy ciepły klimat i przyjazną atmosferę na lekcjach.</li>{" "}
      <li>Oferujemy atrakcyjne ceny.</li>
    </ul>
    <p>
      Placówka posiada status Centrum Przygotowującego do egzaminów Cambridge
      English Qualifications i jest partnerem Autoryzowanego Centrum
      Egzaminacyjnego Cambridge English PL277.
    </p>
  </Wrapper>
)

export default AboutPage
